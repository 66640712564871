<template>
  <div class="photo-swipe">
    <div v-if="isGallery"
         class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

      <!-- Background of PhotoSwipe.
           It's a separate element as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">

        <!-- Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">

          <div class="pswp__top-bar">

            <!--  Controls are self-explanatory. Order can be changed. -->

            <div class="pswp__counter"></div>

            <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

            <button class="pswp__button pswp__button--share" title="Share"></button>

            <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

            <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader--active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
          </button>

          <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
          </button>

          <div class="pswp__caption">
            <div class="pswp__caption__center"></div>
          </div>

        </div>

      </div>
    </div>

    <div class="container">
      <div class="tile"
           v-for="(item, index) in photoNumber"
           :key="index">
        <img @click="initPhotoswipe(index)"
             :src="require(`../assets/gallery/${folder}/${item}.jpg`)">
      </div>
    </div>
  </div>
</template>

<script>
  import 'photoswipe/dist/photoswipe.css';
  import 'photoswipe/dist/default-skin/default-skin.css';
  import 'photoswipe/dist/photoswipe.min';
  import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.min';
  import PhotoSwipe from 'photoswipe';

  export default {
    name: 'PhotoSwipe',
    props: {
      folder: String,
      photoNumber: Number
    },
    data () {
      return {
        pswp: undefined,
        isGallery: false
      }
    },
    methods: {
      initPhotoswipe (index) {
        this.isGallery = true;
        this.$nextTick(() => {
          const pswpElement = document.querySelectorAll('.pswp')[0];
          const items = new Array(7).fill(0).map((val, idx) => {
            return {
              src: require(`../assets/gallery/${this.folder}/${idx + 1}.jpg`),
              w: 900,
              h: 900,
              title: 'SOME TITLE'
            }
          });
          const options = {
            index
          };
          this.pswp = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
          this.pswp.init();
          this.pswp.listen('close', () => {
            // console.log('close')
          });
        })
      }
    }
  }
</script>

<style lang="scss">
  .photo-swipe {
    .container {
      max-width: 800px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 1rem;
      img {
        height: 400px;
      }
    }
    .tile img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .tile:nth-child(1),
    .tile:nth-child(7),
    .tile:nth-child(14)
    {
      grid-column: span 4;
      grid-row: span 2;
    }

    .tile:nth-child(2),
    .tile:nth-child(9),
    .tile:nth-child(16),
    .tile:nth-child(3),
    .tile:nth-child(10),
    .tile:nth-child(17)
    {
      grid-column: span 2;
    }
    .tile:nth-child(4),
    .tile:nth-child(11),
    .tile:nth-child(18),
    .tile:nth-child(5),
    .tile:nth-child(12),
    .tile:nth-child(19)
    {
      grid-column: span 3;
    }
    .tile:nth-child(6),
    .tile:nth-child(13),
    .tile:nth-child(20)
    {
      grid-column: span 4;
      grid-row: span 2;
    }
    .tile:nth-child(7),
    .tile:nth-child(14),
    .tile:nth-child(21),
    .tile:nth-child(8),
    .tile:nth-child(15),
    .tile:nth-child(22)
    {
      grid-column: span 2;
    }

    /* ****** */

    .tile:nth-child(23),
    .tile:nth-child(30),
    .tile:nth-child(37),
    .tile:nth-child(44),
    {
      grid-column: span 4;
      grid-row: span 2;
    }

    .tile:nth-child(24),
    .tile:nth-child(31),
    .tile:nth-child(38),
    .tile:nth-child(45),
    .tile:nth-child(25),
    .tile:nth-child(32),
    .tile:nth-child(39),
    .tile:nth-child(46),
    {
      grid-column: span 2;
    }
    .tile:nth-child(26),
    .tile:nth-child(33),
    .tile:nth-child(40),
    .tile:nth-child(47),
    .tile:nth-child(27),
    .tile:nth-child(34),
    .tile:nth-child(41),
    .tile:nth-child(48),
    {
      grid-column: span 3;
    }
    .tile:nth-child(28),
    .tile:nth-child(35),
    .tile:nth-child(42),
    .tile:nth-child(49),
    {
      grid-column: span 4;
      grid-row: span 2;
    }
    .tile:nth-child(29),
    .tile:nth-child(36),
    .tile:nth-child(43),
    .tile:nth-child(50),
    .tile:nth-child(30),
    .tile:nth-child(37),
    .tile:nth-child(44),
    .tile:nth-child(51),
    {
      grid-column: span 2;
    }


    @media screen and (max-width: 650px){
      .container{
        display: block;
      }
      .tile{
        margin-bottom: 1rem;
      }
    }
  }
</style>
