<template>
  <div id="app" class="app">
<!--    <header class="flex align-center fixed">-->
<!--      <span class="m-left-auto">Florin Ghenade</span>-->
<!--      <div id="nav-icon2"-->
<!--           @click="toggleMenu"-->
<!--           :class="{open: isMenuOpen}">-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--        <span></span>-->
<!--      </div>-->
<!--      <nav class="main-menu text-right fixed right0"-->
<!--           :class="{visible: isMenuOpen}">-->
<!--        <router-link :to="`/${item.replace(/ |\//g, '-').toLowerCase()}`"-->
<!--                     v-for="(item, index) in menu"-->
<!--                     :key="index">-->
<!--          {{ item }}-->
<!--        </router-link>-->
<!--      </nav>-->
<!--    </header>-->
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data () {
      return {
        isMenuOpen: false,
        menu: [
          'Home',
          'Old Trees',
          'Sona Residency',
          'A Cabinet of curiosities',
          'Commissioned',
          'Video',
          'Books',
          'About/Contact'
        ]
      }
    },
    methods: {
      toggleMenu () {
        this.isMenuOpen = !this.isMenuOpen;
      }
    },
    watch: {
      $route(to, from) {
        this.isMenuOpen = false;
      }
    }
  }
</script>

<style lang="scss">
  :root {
    //--bg-color: #f9f9ed;
    --bg-color: #FAFAFA;
  }
  .app {
    background-color: var(--bg-color);
    width: 100%;
    min-height: 100%;
    padding: 100px 0;
    header {
      width: 100%;
      padding: 10px 24px;
      background-color: var(--bg-color);
      border-bottom: 1px solid lightgray;
      top: 0;
      right: 0;
      span {
        margin-right: 24px;
        font-weight: 100;
        font-size: 2.4rem;
      }
      h1 + i {
        transform: translateY(4px);
      }
    }
    .main-menu {
      top: 80px;
      padding: 20px 40px 20px 20px;
      background-color: white;
      transform: translateX(100%);
      transition: transform 150ms ease-in-out;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
      border: 1px solid lightgray;
      a {
        padding-bottom: 12px;
        font-size: 2rem;
        display: block;
        text-decoration: none;
        color: #444444;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .main-menu.visible {
      transform: translateX(0);
    }

    .router-link-active {
      text-decoration: underline!important;
    }

    #nav-icon2 {
      width: 60px;
      height: 45px;
      position: relative;
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      transform: scale(0.6) translateY(3px);
    }
    #nav-icon2 span {
      display: block;
      position: absolute;
      height: 5px;
      width: 50%;
      background: black;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    #nav-icon2 span:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }
    #nav-icon2 span:nth-child(odd) {
      left:0px;
      border-radius: 9px 0 0 9px;
    }
    #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
      top: 0px;
    }
    #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
      top: 18px;
    }
    #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
      top: 36px;
    }
    #nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    #nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    #nav-icon2.open span:nth-child(1) {
      left: 5px;
      top: 7px;
    }
    #nav-icon2.open span:nth-child(2) {
      left: calc(50% - 5px);
      top: 7px;
    }
    #nav-icon2.open span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    #nav-icon2.open span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
    #nav-icon2.open span:nth-child(5) {
      left: 5px;
      top: 29px;
    }
    #nav-icon2.open span:nth-child(6) {
      left: calc(50% - 5px);
      top: 29px;
    }
  }
</style>
