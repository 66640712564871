<template>
  <section class="under-construction flex-center">
    <img src="https://www.unescap.org/sites/default/d8files/inline-images/5_FAQs%20Header.jpg" alt="">
  </section>
</template>

<script>
  export default {
    name: 'UnderConstruction'
  }
</script>

<style lang="scss">
  .under-construction {
    padding-top: 100px;
    img {
      max-width: 500px;
    }
  }
</style>
