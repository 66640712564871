import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Index
  },
  {
    path: '/old-trees',
    name: 'OldTrees',
    component: () => import('../views/OldTrees')
  },
  {
    path: '/sona-residency',
    name: 'SonaResidency',
    component: () => import('../views/SomaResidency')
  },
  {
    path: '/a-cabinet-of-curiosities',
    name: 'CabinetOfCuriosities',
    component: () => import('../views/CabinetOfCurisities')
  },
  {
    path: '/commissioned',
    name: 'Commisioned',
    component: () => import('../views/Commissioned')
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/Video')
  },
  {
    path: '/books',
    name: 'Books',
    component: () => import('../views/Books')
  },
  {
    path: '/about-contact',
    name: 'About',
    component: () => import('../views/About')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
