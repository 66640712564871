<template>
  <main class="home-page">
    <div class="jumbotron text-center">
<!--      <h1>Welcome to my website</h1>-->
    </div>

    <PhotoSwipe folder="home"
                :photo-number="17" />

  </main>
</template>

<script>
  export default {
    name: 'Home'
  }
</script>

<style lang="scss">
  .home-page {

  }
</style>
